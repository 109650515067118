.container {
    width: 60vw;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 768px) {
        width: 320px;
        text-align: center;
}
}

.information {
    width: 350px;
    text-align: center;

    @media(max-width: 768px) {
            width: 200px;
    }
}

.emoji {
    margin-bottom: 20px;
}