.title-stroke {
  position: relative;
}

.title-stroke:before {
  content:' ';
  height: 4px;
  width: 24px;
  background: #8468DE;
  bottom: -8px;
  left: 1px;
  border-radius: 8px;
  position: absolute;
}

img.svg-white,
.svg-white img{
  filter: brightness(0) invert(1);
}

.slick-alolan-wrapper {
  width: 100%;
}
.slick-alolan {
  position: relative;

  .slick-dots {
    bottom: -40px !important;
  }

  .slick-dots li button:before{
    width: 13px;
    height: 13px;
    opacity: 1;
    border-radius: 100%;
    content: ' ';
    border: 2px solid white;
    background: white;
  }

  .slick-dots li.slick-active button:before{
    border: 2px solid #707070;
    background: #262C36;
  }

  .slick-track {
    display: flex;
    flex-wrap: nowrap;
  }
  .slick-disabled {
    display: none;
  }
}
