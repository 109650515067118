.headerContainer {
    width: 100vw;
    height: 10vh;
    left: 0px;
    top: 0px;
    background: linear-gradient(90deg, #052D55 0%, #0A56A0 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
  
.generalContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    padding: 0;
    margin: 0;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ECF0F4;
    width: 100vw;
    padding-bottom: 20px;
}

.boxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 73vw;
    margin: 30px 0 30px 0;
}

.boxSubtitle {
    margin-top: 17px;
}

.containerForm {
    width: 70vw;
    padding: 20px 10px 10px 20px;
}

.pdfBox {
    width: 68vw;
    box-shadow: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iframe {
	width: 100%;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.image {
    max-width: 100%;
    max-height: 100%;
}

.containerPhone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 45vw;
    margin-top: 40px;
    background: #ECF0F4;
    padding: 20px;
    border-radius: 6px;
}

.title {
    text-align: center;
    width: 60%;
}

.phone {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.codeWrapper{
    margin-top: 40px;
}

.codeWrapper input {
    background: #ECF0F4;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: #0052CC;
}

.approvationWrapper {
    margin: 20px 0 40px 0;
    display: flex;
    height: 12vh;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.checkboxWrapper {
    width: 480px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
}
.codeInput {
    display: flex;
    margin-bottom: 5px;
}

.totalValueWrapper {
    width: 328px;
    background-color: #F8F9FB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 9px;
    padding: 10px 0 15px 20px;
}

.totalValue {
    margin-top: -10px;
    font-weight:bold;
}

.modalWrapper {
    display: flex;
    height: 87vh;
    align-items: center;
    justify-content: center;
}

.tokenError {
    margin-top: 10px;
}

// mobile
@media(max-width: 768px) {
    .totalValueWrapper {
        width: 270px;
        margin-bottom: 16px;
    }

    .checkboxWrapper {
        width: 200px;
    }

    .boxWrapper {
        width: 310px;
    }

    .boxTitle{
        width: 290px;
        text-align: center;
    }

    .containerForm{
        width: 300px;
    }

    .containerPhone{
        width: 270px;
    }

    .title,
    .phone{
        width: 260px;
    }

    .sendCodeButton {
        display: flex;
        justify-content: center;
    }

    .checkboxWrapper {
        width: 280px;
    }

    .checkbox {
        margin-right: 5px;
    }

    .pdfBox {
        display: flex;
        justify-content: center;
    }
}
